import React, { Fragment, useEffect,useMemo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Header,
  Sidebar,
  BreadCrumb,
} from 'shared/components';
import dataMenuAdmin from 'api/ui/menuAdmin';
import dataMenuHealthInsurance from 'api/ui/menuHealthInsurance';
import dataMenuServiceProvider from 'api/ui/menuServiceProvider';
import dataMenuPatient from 'api/ui/menuPatient';
import dataMenuAttendant from 'api/ui/menuAttendant';
import { useSelector } from 'react-redux';
import Decoration from '../Decoration';
import styles from '../appStyles-jss';
import imgSpinner from 'shared/images/spinner.gif';

function LeftSidebarLayout(props) {
  const [menu, setmenu] = React.useState([]);
  const session = useSelector(state => state.getIn(['session', 'session']));
  const profile = useMemo(() => session.get('profileType'), [session])
  
  useEffect(() => {
    if (profile === 0) 
      { setmenu(dataMenuAdmin); }
    else if (profile === 1) 
      { setmenu(dataMenuHealthInsurance); }
    else if (profile === 2) 
      { setmenu(dataMenuServiceProvider); }
    else if (profile === 4) 
      { setmenu(dataMenuPatient); }
    else if (profile === 5) 
      { setmenu(dataMenuAttendant); }
    else 
      { setmenu([]); }
  }, [profile]);

  const {
    classes,
    children,
    toggleDrawer,
    sidebarOpen,
    loadTransition,
    pageLoaded,
    mode,
    gradient,
    deco,
    history,
    bgPosition,
    changeMode,
    place,
    titleException,
    handleOpenGuide
  } = props;
  return (
    <Fragment>
      <Header
        toggleDrawerOpen={toggleDrawer}
        margin={sidebarOpen}
        gradient={gradient}
        position="left-sidebar"
        changeMode={changeMode}
        mode={mode}
        title={place}
        history={history}
        openGuide={handleOpenGuide}
      />
      <Sidebar
        open={sidebarOpen}
        toggleDrawerOpen={toggleDrawer}
        loadTransition={loadTransition}
        dataMenu={menu}
        leftSidebar
      />
      <main className={classNames(classes.content, !sidebarOpen ? classes.contentPaddingLeft : '')} id="mainContent" >        
        <Decoration
          mode={mode}
          gradient={gradient}
          decoration={deco}
          bgPosition={bgPosition}
          horizontalMenu={false}
        />
        <section className={classNames(classes.mainWrap, classes.sidebarLayout)} >
          {titleException.indexOf(history.location.pathname) < 0 && (
            <div className={classes.pageTitle}>
              <Typography component="h4" className={bgPosition === 'header' ? classes.darkTitle : classes.lightTitle} variant="h4">
                {place}
              </Typography>
              {/* <BreadCrumb separator=" / " theme={bgPosition === 'header' ? 'dark' : 'light'} location={history.location} /> */}
            </div>
          )}
          { !pageLoaded && (<img src={imgSpinner} alt="spinner" className={classes.circularProgress} />) }
          <Fade
            in={pageLoaded}
            {...(pageLoaded ? { timeout: 700 } : {})}
          >
            <div className={!pageLoaded ? classes.hideApp : ''}>
              {/* Application content will load here */}              
              { children }
            </div>
          </Fade>
        </section>
      </main>
    </Fragment>
  );
}

LeftSidebarLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  titleException: PropTypes.array.isRequired,
  handleOpenGuide: PropTypes.func.isRequired
};

export default (withStyles(styles)(LeftSidebarLayout));
