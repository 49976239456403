import React from 'react';
import { PropTypes } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import cssLoading from 'styles/components/Loading.scss';

function Loading(props) {
  return (
    <div className={cssLoading.boxCircular}>
      <CircularProgress className={cssLoading.circularP} size={80} thickness={1} color="secondary" />
      Carregando...
    </div>
  );
}

Loading.propTypes = {
};
export default (Loading);
