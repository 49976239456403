const { default: routes } = require('navigation/routes');
const { serviceProviderRoutes } = routes;

module.exports = [
  {
    key: 'home',
    name: 'Home',
    icon: 'ios-home-outline',
    linkParent: serviceProviderRoutes.home
  },
  // {
  //   key: 'email',
  //   name: 'Notificações',
  //   badge: '4',
  //   icon: 'ios-chatbubbles-outline',
  //   linkParent: '/app/pages/email'
  // }
];
