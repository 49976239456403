const { default: routes } = require('navigation/routes');
const { attendantRoutes } = routes;

module.exports = [
  {
    key: 'home',
    name: 'Home',
    icon: 'ios-home-outline',
    linkParent: attendantRoutes.home
  },
  {
    key: 'cadastros',
    name: 'Cadastros',
    icon: 'ios-list-box-outline',
    child: [
      {
        key: 'data_table',
        name: 'Convênios',
        link: attendantRoutes.healthInsuranceList,
        icon: 'ios-cube-outline'
      },
      {
        key: 'data_table',
        name: 'Prestador de Serviços',
        link: attendantRoutes.serviceProviderList,
        icon: 'ios-cube-outline'
      },
      {
        key: 'data_table',
        name: 'Beneficiários',
        link: attendantRoutes.beneficiaryList,
        icon: 'ios-cube-outline'
      },
    ]
  },
  {
    key: 'journeyMenu',
    name: 'Acomp. Prestador',
    icon: 'ios-people-outline',
    linkParent: attendantRoutes.journeyMenu,
  },
  {
    key: 'managementQuestionnaire',
    name: 'Gestão Questionários',
    icon: 'ios-sync',
    linkParent: attendantRoutes.managementQuestionnaire,
  },
];
