import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
    NotFoundPage,
    ProfilePage,
    ChangePassWordPage,
    // HomePage,
} from '../../pages/';
import routes from '../routes';


function HealthInsuranceRoutes() {
    const { healthInsuranceRoutes } = routes;
    return (
        <Switch>
            { /* Convenio */ }
            {/* <Route exact path={healthInsuranceRoutes.home} component={HomePage} /> */}

            <Route exact path={healthInsuranceRoutes.profile} component={ProfilePage} />
            <Route exact path={healthInsuranceRoutes.changePassword} component={ChangePassWordPage} />

            <Route component={NotFoundPage} />
        </Switch>
    );
}

export default HealthInsuranceRoutes;
