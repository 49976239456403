import React, { useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TrendingUp from '@material-ui/icons/TrendingUp';
import TrendingDown from '@material-ui/icons/TrendingDown';
import TrendingFlat from '@material-ui/icons/TrendingFlat';
import PapperBlock from '../PapperBlock/PapperBlock';
import EnhancedTableToolbar from './tableParts/TableToolbar';
import EnhancedTableHead from './tableParts/TableHeader';
import styles from './tableStyle-jss';
import LoadingBox from 'shared/components/LoadingBox';

function TopPrognosticRiskTable(props) {
  const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case 'SET_ORDER':
        return { ...state, order: payload };
      case 'SET_ORDERBY':
        return { ...state, orderBy: payload };
      case 'SET_SELECTED':
        return { ...state, selected: payload };
      case 'SET_DATA':
        return { ...state, data: payload };
      case 'SET_PAGE':
        return { ...state, page: payload };
      case 'SET_ROWSPERPAGE':
        return { ...state, rowsPerpage: payload };
      case 'SET_FILTERTEXT':
        return { ...state, filterText: payload };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, props);

  const {
    order,
    orderBy,
    data,
    page,
    rowsPerPage,
    defaultPerPage,
    filterText,
    columnData,
    isLoading
  } = props;

  const { classes } = props;

  useEffect(() => {
    dispatch({ type: 'SET_DATA', payload: data.sort((a, b) => (a.clinic < b.clinic ? -1 : 1)) });
  }, []);

  const handleRequestSort = useCallback((event, property) => {
    const orderByAlias = property;
    let orderLet = 'desc';
    if (orderBy === property && order === 'desc') {
      orderLet = 'asc';
    }

    const dataAlias = orderLet === 'desc'
      ? data.sort((a, b) => (b[orderByAlias] < a[orderByAlias] ? -1 : 1))
      : data.sort((a, b) => (a[orderByAlias] < b[orderByAlias] ? -1 : 1));

    dispatch({ type: 'SET_DATA', payload: dataAlias });
    dispatch({ type: 'SET_ORDER', payload: orderLet });
    dispatch({ type: 'SET_ORDERBY', payload: orderByAlias });
  }, [order, orderBy, data]);

  const handleChangePage = useCallback((event, selectedPage) => {
    dispatch({ type: 'SET_PAGE', payload: selectedPage });
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    dispatch({ type: 'SET_ROWSPERPAGE', payload: event.target.value });
    dispatch({ type: 'SET_PAGE', payload: 0 });
  }, []);

  const handleUserInput = useCallback((value) => {
    // Show all item first
    if (value !== '') {
      dispatch({ type: 'SET_ROWSPERPAGE', payload: data.length });
    } else {
      dispatch({ type: 'SET_ROWSPERPAGE', payload: defaultPerPage });
    }
    dispatch({ type: 'SET_FILTERTEXT', payload: value.toLowerCase() });
  }, [data, defaultPerPage]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));
  const getCondition = (pos, val) => {
    if (val === 'Não') {
      return (
        <span className={classes.up}>
          <TrendingUp />
          &nbsp;
          {val}
        </span>
      );
    }
    if (val === 'Sim') {
      return (
        <span className={classes.down}>
          <TrendingDown />
          &nbsp;
          {val}
        </span>
      );
    }
    return (
      <span className={classes.flat}>
        <TrendingFlat />
          &nbsp;
          {val}
      </span>
    );
  };

  const renderCell = (dataArray, keyArray) => keyArray.map((itemCell, index) => {
    if (itemCell.id === 'name') {
      return (
        <TableCell padding="default" key={index.toString()}>
          <div className={classes.flex}>
            <div>
              <Typography variant="subtitle1" style={{fontSize:'13px'}}>{dataArray[itemCell.id]}</Typography>
              <Typography variant="caption">CPF: {dataArray.cpf}</Typography>
            </div>
          </div>
        </TableCell>
      );
    }
    return (
      <TableCell padding="default"  key={index.toString()}>
        {dataArray[itemCell.id]}
      </TableCell>
    );
  });
  return (
    <PapperBlock noMargin title="Risco Prognóstico" icon="ios-basket-outline" whiteBg desc="Top 5 Pacientes Alto Risco">

      {isLoading && <LoadingBox />}
      {!isLoading &&
      <div className={classes.rootTable}>
        <EnhancedTableToolbar
          numSelected={0}
          filterText={filterText}
          onUserInput={(event) => handleUserInput(event)}
          title="Métricas Desfechos Clínicos"
          placeholder="Pesquisar"
        />
        <div className={classes.tableWrapper}>
          <Table padding="default" className={classNames(classes.table, classes.stripped, classes.hover)}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              columnData={columnData}
              numSelected={0}
            />
            <TableBody>
              {data.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(n => {
                if (n.clinic.toLowerCase().indexOf(filterText) === -1) {
                  return false;
                }
                return (
                  <TableRow
                    tabIndex={-1}
                    key={n.patientId}
                  >
                    {renderCell(n, columnData)}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {/*<TablePagination
          component="div"
          count={data.length}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />*/}
      </div>
      }
    </PapperBlock>
  );
}

TopPrognosticRiskTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TopPrognosticRiskTable);
