import React, { useLayoutEffect } from 'react';
import { getSessionCookie } from 'data/cache/cookies';
import { SessionService } from 'services';
import { useSelector } from 'react-redux';
import { isAfter } from 'date-fns';

function AuthVerify() {
  const session = useSelector(state => state.getIn(['session', 'session']));
  const router = useSelector(state => state.getIn(['router']));

  useLayoutEffect(() => {
    verifyTokenHasExpired();
  }, [router, session]);

  useLayoutEffect(() => {
    verifyTokenInCookies();
  }, []);

  function verifyTokenHasExpired() {
    const authenticated = session && session.get('authenticated');
    if (authenticated === true) {
      const expiration = session && session.get('expiration');
      if (expiration) {
        const expirationDate = new Date(expiration);
        const now = new Date();
        const hasExpire = isAfter(now, expirationDate);

        if (hasExpire) SessionService.finishSession();
      }
    }
  }

  function verifyTokenInCookies() {
    const session = getSessionCookie();
    if (session) {
      SessionService.initSession(session);
    }
  }

  return null;
}

export default AuthVerify;
