import { Api } from 'config';
import store from 'data/redux/store';
import { setSessionCookie, removeSessionCookie } from 'data/cache/cookies';
import { initSession as initSessionAction, updateSession as updateSessionAction, finishSession as finishSessionAction } from 'data/redux/actions/sessionActions';

export async function initSession(session) {  
  setSessionCookie(session);
  Api.grantAuthorization(session.accessToken);
  store.dispatch(initSessionAction(session));
}

export async function updateSession(session) {
  console.log('updateSession');
  setSessionCookie(session);
  store.dispatch(updateSessionAction(session));
}

export async function finishSession() {
  console.log('finishSession');
  removeSessionCookie();
  Api.removeAuthorization();
  store.dispatch(finishSessionAction);
}
