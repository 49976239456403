const { default: routes } = require('navigation/routes');
const { healthInsuranceRoutes } = routes;

module.exports = [
  {
    key: 'home',
    name: 'Home',
    icon: 'ios-home-outline',
    linkParent: healthInsuranceRoutes.home
  }
];
