import { Api } from 'config';

const beneficiaryEndpoint = 'Beneficiary';

export async function getAllByPageAndName(PageNumber, PageSize, Name) {
  const params = {
    PageNumber,
    PageSize,
    Name,
  };
  const response = await Api.get(beneficiaryEndpoint, params);

  return response;
}

export async function getSearch(PageNumber, PageSize, Name) {
  const params = {
    PageNumber,
    PageSize,
    Name,
  };
  const endpoint = beneficiaryEndpoint + `/Search`;
  const response = await Api.get(endpoint, params);

  return response;
}

export async function getAllAvailableByPageAndName(PageNumber, PageSize, Name) {
  const params = {
    PageNumber,
    PageSize,
    Name,
  };
  const endpoint = beneficiaryEndpoint + `/Available`;
  const response = await Api.get(endpoint, params);

  return response;
}

export async function getFromTypeForm() {
  const endpoint = beneficiaryEndpoint + `/FromTypeForm`;
  const response = await Api.get(endpoint);

  return response;
}

export async function getById(beneficiaryId) {
  const endpoint = beneficiaryEndpoint + `/${beneficiaryId}`;
  const response = await Api.get(endpoint);

  return response;
}

export async function create(payload) {
  const response = await Api.post(beneficiaryEndpoint, payload);

  return response;
}

export async function update(beneficiaryId,payload) {
  const endpoint = beneficiaryEndpoint + `/${beneficiaryId}`;
  const response = await Api.put(endpoint, payload);

  return response;
}

export async function deleteBeneficiary(beneficiaryId,payload) {
  const endpoint = beneficiaryEndpoint + `/Delete/${beneficiaryId}`;
  const response = await Api.deleteWithPayload(endpoint,payload);

  return response;
}

export async function getTypeFormErrorLog(PageNumber, PageSize, Name) {
  const params = {
    PageNumber,
    PageSize,
    Name,
  };
  const endpoint = beneficiaryEndpoint + `/TypeFormErrorLog`;
  const response = await Api.get(endpoint, params);

  return response;
}

export async function getErrorAnwserFormLogById(anwserFormLogId) {
  const endpoint = beneficiaryEndpoint + `GetErrorAnwserFormLogById/${anwserFormLogId}`;
  const response = await Api.get(endpoint);

  return response;
}

export async function updateStatusPending(anwserFormLogId,payload) {
  const endpoint = beneficiaryEndpoint + `/UpdateStatusPending/${anwserFormLogId}`;
  const response = await Api.post(endpoint, payload);

  return response;
}

export async function importApi(payload) {
  const endpoint = beneficiaryEndpoint + `/ImportApi`;
  const response = await Api.post(endpoint, payload);

  return response;
}

export async function getCsvTemplateImport() {
  const endpoint = beneficiaryEndpoint + `/CsvTemplateImport`;
  const response = await Api.get(endpoint);

  return response;
}

export async function importCsv(payload) {
  const endpoint = beneficiaryEndpoint + `/ImportCsv`;
  const response = await Api.post(endpoint, payload);

  return response;
}