import React from 'react';
import { Radar } from 'react-chartjs-2';
import colorfull from 'api/palette/colorfull';

const data = {
  labels: ['Risco Prognóstico', 'Dor', 'Incapacidade', 'Cognitivo-comportamental', 'Socioambiental', 'Emocional', 'Qualidade de Vida', 'Sono', 'Auto-eficácia'],
  datasets: [
    {
      label: '15/03/2021',
      backgroundColor: colorfull[0] + '20',
      borderColor: colorfull[0],
      pointBackgroundColor: colorfull[0],
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: colorfull[0],
      data: [12, 8, 7, 5, 5, 3, 7, 9, 7]
    },
    {
      label: '30/03/2021',
      backgroundColor: colorfull[1] + '20',
      borderColor: colorfull[1],
      pointBackgroundColor: colorfull[1],
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: colorfull[1],
      data: [12, 3, 15, 4, 8, 10, 8, 6, 1]
    }
  ]
};

function RadarBasic() {
  return (
    <div>
      <Radar data={data} />
    </div>
  );
}

export default RadarBasic;