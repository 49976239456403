const routes = {
  loggedOutRoutes: {
    login: '/',
    register: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    formInitialAssessmentPageUnimed: '/form/avaliacao-inicial/unimed',
    formRevaluationPageUnimed: '/form/reavaliacao/unimed',
    formInitialAssessmentPage: '/form/avaliacao-inicial/',
    formRevaluationPage: '/form/reavaliacao/',
    dashiasis: '/dash/iasis/',
    dashlife: '/dash/life/'
  },
  formRoutes: {
    formInitialAssessmentPageUnimed: '/form/avaliacao-inicial/unimed',
    formRevaluationPageUnimed: '/form/reavaliacao/unimed',
    formInitialAssessmentPage: '/form/avaliacao-inicial/',
    formRevaluationPage: '/form/reavaliacao/',
  },
  adminRoutes: {
    // home: '/',
    profile: '/profile',
    changePassword: '/changePassword',
    healthInsuranceList: '/registrations/list/healthInsurance',
    healthInsuranceForm: '/registrations/form/healthInsurance',
    serviceProviderList: '/list/serviceProvider',
    serviceProviderForm: '/form/serviceProvider',
    serviceProviderImport: '/import/serviceProvider',
    beneficiaryList: '/registrations/list/beneficiary',
    beneficiaryForm: '/registrations/form/beneficiary',
    userForm: '/form/user',
    userList: '/list/user',
    punctuationSetupList: '/registrations/list/punctuationSetup',
    punctuationSetupForm: '/registrations/form/punctuationSetup',
    sectorList: '/list/sector',
    sectorForm: '/form/sector',
    metricList: '/registrations/list/metric',
    metricForm: '/registrations/form/metric',
    segmentList: '/registrations/list/segment',
    segmentForm: '/registrations/form/segment',
    managementQuestionnaire: '/managementQuestionnaire/list',
    journeyMenu: '/',
    dashCostEffectiveness: '/dashCostEffectiveness',
    predictiveAnalytics: '/predictiveAnalytics',
    patientDetail: '/patientDetail',
  },
  beneficiaryRoutes: {
    home: '/',
    profile: '/profile',
    changePassword: '/changePassword',
  },
  healthInsuranceRoutes: {
    home: '/',
    profile: '/profile',
    changePassword: '/changePassword',
  },
  serviceProviderRoutes: {
    home: '/',
    profile: '/profile',
    changePassword: '/changePassword',
  },
  attendantRoutes: {
    home: '/',
    profile: '/profile',
    changePassword: '/changePassword',
    healthInsuranceList: '/registrations/list/healthInsurance',
    healthInsuranceForm: '/registrations/form/healthInsurance',
    serviceProviderList: '/list/serviceProvider',
    serviceProviderForm: '/form/serviceProvider',
    serviceProviderImport: '/import/serviceProvider',
    beneficiaryList: '/registrations/list/beneficiary',
    beneficiaryForm: '/registrations/form/beneficiary',
    managementQuestionnaire: '/managementQuestionnaire/list',
    journeyMenu: '/journeyMenu',
  },
};

export default Object.freeze(routes);
