import { Api } from 'config';

const authEndpoint = 'User';


export async function authenticate(payload) {
  const endpoint = authEndpoint + `/Authenticate`;
  const response = await Api.post(endpoint, payload);

  return response;
}

export async function update(id,payload) {
  const endpoint = authEndpoint + `/${id}`;
  const response = await Api.put(endpoint, payload);

  return response;
}

export async function getById(id) {
  const endpoint = authEndpoint + `/${id}`;
  const response = await Api.get(endpoint);

  return response;
}

export async function resetPassword(login) {
  const endpoint = authEndpoint + `/SetUserToResetPassword/${login}`;
  const response = await Api.get(endpoint);

  return response;
}

export async function verifyResetPassword(payload) {
  const endpoint = authEndpoint + `/VerifyResetPassword`;
  const response = await Api.post(endpoint,payload);

  return response;
}

export async function changePassword(payload) {
  const endpoint = authEndpoint + `/ChangePassword`;
  const response = await Api.post(endpoint, payload);

  return response;
}
