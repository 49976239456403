/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import LoggedInRoutes from '../LoggedInRoutes';
import LoggedOutRoutes from '../LoggedOutRoutes';
import FormRoutes from '../FormRoutes';
import history from '../../shared/utils/history';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

function AppRoutes() {
  const session = useSelector(state => state.getIn(['session', 'session']));
  const authenticated = session && session.get('authenticated');

  if (authenticated) {
    if (history.location.pathname.includes('form'))
      return <FormRoutes/>;

    return <LoggedInRoutes />;
  }

  return (
    <LoggedOutRoutes />
  );
}

export default AppRoutes;
