

/* eslint-disable react/prop-types */
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import ProfileType from '../../shared/enums/ProfileType';
import Dashboard from '../../shared/templates/Dashboard';
import history from '../../shared/utils/history';
import HealthInsuranceRoutes from '../HealthInsuranceRoutes';
import AdminRoutes from '../AdminRoutes';
import { ThemeContext } from '../../shared/components/ThemeWrapper';

function CorrectProfileRoute() {
  const session = useSelector(state => state.getIn(['session', 'session']));
  const profileType = useMemo(() => session && session.get('profileType'), [session]);

  switch (profileType) {
    case ProfileType.ADMIN: {
      return <AdminRoutes />;
    }
    default: {
      return <HealthInsuranceRoutes />;
    }
  }
}


function LoggedInRoutes() {
  const changeMode = useContext(ThemeContext);

  return (
    <Dashboard history={history} changeMode={changeMode}>
      <CorrectProfileRoute />
    </Dashboard>
  );
}

export default LoggedInRoutes;
