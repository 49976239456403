import * as Cookies from 'js-cookie';
import React from 'react';

export const setSessionCookie = (session) => {
  Cookies.remove('session');
  const expireDate = session.remember ? new Date(session.expiration) : undefined;

  Cookies.set('session', session, { expires: expireDate });
  //Cookies.set('session', session, { expires: 14 }); 
};

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get('session');
  if (sessionCookie === undefined) {
    return null;
  }
  return JSON.parse(sessionCookie);
};

export const removeSessionCookie = () => {
  Cookies.remove('session');
};

export const SessionContext = React.createContext(getSessionCookie());

export const removeAllCookies = () => {
  Object.keys(Cookies.get()).forEach((cookie) => {
    if (cookie !== 'language') Cookies.remove(cookie);
  });
};
