import React from 'react';
import Loading from 'shared/components/Loading';
import loadable from 'shared/utils/loadable';


// Auth pages
export const LoginPage = loadable(() => import('./Auth/LoginPage'), {
  fallback: <Loading />,
});

export const RegisterPage = loadable(() => import('./Auth/RegisterPage'), {
  fallback: <Loading />,
});

export const ForgotPasswordPage = loadable(() => import('./Auth/ForgotPasswordPage'), {
  fallback: <Loading />,
});

export const ResetPasswordPage = loadable(() => import('./Auth/ResetPasswordPage'), {
  fallback: <Loading />,
});

// Admin pages
export const HealthInsuranceListPage = loadable(() => import('./Admin/Registrations/HealthInsurance/HealthInsuranceListPage'), {
  fallback: <Loading />,
});

export const HealthInsuranceFormPage = loadable(() => import('./Admin/Registrations/HealthInsurance/HealthInsuranceFormPage'), {
  fallback: <Loading />,
});

export const ListServiceProviderPage = loadable(() => import('./Admin/Registrations/ServiceProvider/ListServiceProviderPage'), {
  fallback: <Loading />,
});

export const FormServiceProviderPage = loadable(() => import('./Admin/Registrations/ServiceProvider/FormServiceProviderPage'), {
  fallback: <Loading />,
});

export const ImportServiceProviderPage = loadable(() => import('./Admin/Registrations/ServiceProvider/ImportServiceProviderPage'), {
  fallback: <Loading />,
});

export const FormUserPage = loadable(() => import('./Admin/Users/FormUserPage'), {
  fallback: <Loading />,
});

export const ListUserPage = loadable(() => import('./Admin/Users/ListUserPage'), {
  fallback: <Loading />,
});

export const ListBeneficiaryPage = loadable(() => import('./Admin/Registrations/Beneficiary/ListBeneficiaryPage'), {
  fallback: <Loading />,
});

export const FormBeneficiaryPage = loadable(() => import('./Admin/Registrations/Beneficiary/FormBeneficiaryPage'), {
  fallback: <Loading />,
});

export const MetricListPage = loadable(() => import('./Admin/Registrations/Metric/MetricListPage'), {
  fallback: <Loading />,
});

export const MetricFormPage = loadable(() => import('./Admin/Registrations/Metric/MetricFormPage'), {
  fallback: <Loading />,
});

export const SegmentListPage = loadable(() => import('./Admin/Registrations/Segment/SegmentListPage'), {
  fallback: <Loading />,
});

export const SegmentFormPage = loadable(() => import('./Admin/Registrations/Segment/SegmentFormPage'), {
  fallback: <Loading />,
});

export const JourneyMenuPage = loadable(() => import('./Admin/JourneyMenuPage'), {
  fallback: <Loading />,
});

export const DashCostEffectiveness = loadable(() => import('./Admin/DashCostEffectiveness'), {
  fallback: <Loading />,
});

export const PredictiveAnalyticsPage = loadable(() => import('./Admin/PredictiveAnalyticsPage'), {
  fallback: <Loading />,
});

export const PatientDetailPage = loadable(() => import('./Admin/PatientDetailPage'), {
  fallback: <Loading />,
});



// Others pages

// export const HomePage = loadable(() => import('./Others/HomePage'), {
//   fallback: <Loading />,
// });

export const FormInitialAssessmentPage = loadable(() => import('./Others/FormPage/InitialAssessment/FormInitialAssessment'), {
  fallback: <Loading />,
});

export const FormRevaluationPage = loadable(() => import('./Others/FormPage/Revaluation/FormRevaluation'), {
  fallback: <Loading />,
});

export const DashIasis = loadable(() => import('./Others/DashPage/DashIasis'), {
  fallback: <Loading />,
});

export const Dash2 = loadable(() => import('./Others/DashPage/Dash2'), {
  fallback: <Loading />,
});

export const NotFoundPage = loadable(() => import('./Others/NotFoundPage'), {
  fallback: <Loading />,
});

export const NotFoundDedicatedPage = loadable(() => import('./Others/NotFoundDedicatedPage'), {
  fallback: <Loading />,
});

export const ErrorPage = loadable(() => import('./Others/ErrorPage'), {
  fallback: <Loading />,
});

export const ProfilePage = loadable(() => import('./Others/ProfilePage'), {
  fallback: <Loading />,
});

export const ChangePassWordPage = loadable(() => import('./Others/ChangePassWordPage'), {
  fallback: <Loading />,
});



