import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
    LoginPage,
    RegisterPage,
    ForgotPasswordPage,
    ResetPasswordPage,
    NotFoundPage,
    FormInitialAssessmentPage,
    FormRevaluationPage,
    DashIasis,
    Dash2
} from '../../pages/';
import Outer from '../../shared/templates/Outer';
import routes from '../routes';

const { loggedOutRoutes } = routes;

function Auth() {
    return (
        <Outer>
            <Switch>
                <Route path={loggedOutRoutes.register} component={RegisterPage} />
                <Route path={loggedOutRoutes.forgotPassword} component={ForgotPasswordPage} />
                <Route path={loggedOutRoutes.resetPassword} component={ResetPasswordPage} />
                <Route component={NotFoundPage} />
            </Switch>
        </Outer>
    );
}


function LoggedOutRoutes() {
    return (
        <Switch>
            <Route path={loggedOutRoutes.login} exact component={LoginPage} />
            <Route path={loggedOutRoutes.formInitialAssessmentPage} component={FormInitialAssessmentPage} />
            <Route path={loggedOutRoutes.formRevaluationPage} component={FormRevaluationPage} />
            <Route path={loggedOutRoutes.dashiasis} component={DashIasis} />
            <Route path={loggedOutRoutes.dashlife} component={Dash2} />

            <Route component={Auth} />
        </Switch>
    );
}

export default LoggedOutRoutes;
