import { fromJS } from 'immutable';
import { INIT_SESSION, UPDATE_SESSION, FINISH_SESSION } from '../constants/sessionConstants';

const initialSession = {
  authenticated: null,
  created: null,
  expiration: null,
  accessToken: null,
  lastAccess: null,
  userId: null,
  name: null,
  email: null,
  active: null,
  profileType: null,
  pathImage: null,
  remember: null
};

const initialState = {
  session: initialSession
};

const initialImmutableState = fromJS(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
  switch (action.type) {
    case INIT_SESSION:
      return state.withMutations((mutableState) => {
        const session = fromJS(action.session);
        mutableState.set('session', session);
      });      
    case UPDATE_SESSION: {
      return state.withMutations((mutableState) => {
        const session = fromJS(action.session);
        mutableState.set('session', session);
      });
    }
    case FINISH_SESSION: {
      return state.withMutations((mutableState) => {
        const session = fromJS(initialSession);
        mutableState.set('session', session);
      });
    }
    default:
      return state;
  }
}
