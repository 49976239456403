import pink from '@material-ui/core/colors/pink';
import cyan from '@material-ui/core/colors/cyan';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';

const colorfull = [
  '#ff5a5f', //Vermelho
  cyan[500],
  green[400],
  blue[600],
  teal[500],
  '#f99c4b', // Laranja
  purple[400],

  //Cores Oficais da Hi
  '#ff5a5f', //Vermelho
  '#058f51', //Verde
  '#26668b', //Azul
  '#3c3c3c', //Chumbo
  '#f5f5f5', //Cinza
  '#FFFF00' //Amarelo
];
export default colorfull;
