import { Api } from 'config';

const userEndpoint = 'User';

export async function getAllByPageAndName(PageNumber, PageSize, Name) {
  const params = {
    PageNumber,
    PageSize,
    Name,
  };
  const response = await Api.get(userEndpoint, params);

  return response;
}

export async function getById(id) {
  const endpoint = userEndpoint + `/${id}`;
  const response = await Api.get(endpoint);

  return response;
}

export async function getActive() {
  const endpoint = userEndpoint + `/Active`;
  const response = await Api.get(endpoint);

  return response;
}

export async function create(payload) {
  const response = await Api.post(userEndpoint, payload);

  return response;
}

export async function update(id,payload) {
  const endpoint = userEndpoint + `/${id}`;
  const response = await Api.put(endpoint, payload);

  return response;
}

export async function getAdminAndAttendants(PageNumber, PageSize, Name) {
  const params = {
    PageNumber,
    PageSize,
    Name,
  };
  const endpoint = userEndpoint + `/ModuleAdmin`;
  const response = await Api.get(endpoint, params);

  return response;
}

export async function getAttendantList() {
  const endpoint = userEndpoint + `/Attendants`;
  const response = await Api.get(endpoint);

  return response;
}