/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */


// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import FontFaceObserver from 'fontfaceobserver';
import history from 'shared/utils/history';
import 'sanitize.css/sanitize.css';

// Import root app
import './styles/layout/base.scss';

// Import root app
import './styles/fix.css';


// Load the favicon and the .htaccess file
//import '!file-loader?name=[name].[ext]!../public/favicons/favicon.png'; // eslint-disable-line
//import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line

import AppRoutes from 'navigation/AppRoutes';
import AuthVerify from 'shared/components/AuthVerify';
import store from './data/redux/store';
import { ThemeWrapper } from 'shared/components/';


// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const MOUNT_NODE = document.getElementById('app');

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeWrapper>
          <AppRoutes />
        </ThemeWrapper>
        <AuthVerify />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['navigation/AppRoutes'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
