import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
    FormInitialAssessmentPage,
    FormRevaluationPage,
} from '../../pages';
import routes from '../routes';

const { formRoutes } = routes;

function FormRoutes() {
    return (
        <Switch>
            <Route path={formRoutes.formInitialAssessmentPage} component={FormInitialAssessmentPage} />
            <Route path={formRoutes.formRevaluationPage} component={FormRevaluationPage} />
        </Switch>
    );
}

export default FormRoutes;
