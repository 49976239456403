const { default: routes } = require('navigation/routes');
const { beneficiaryRoutes } = routes;

module.exports = [
  {
    key: 'home',
    name: 'Home',
    icon: 'ios-home-outline',
    linkParent: beneficiaryRoutes.home
  }
];
