import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
    NotFoundPage,
    // HomePage,
    HealthInsuranceListPage,
    HealthInsuranceFormPage,
    ListServiceProviderPage,
    FormServiceProviderPage,
    ImportServiceProviderPage,
    FormBeneficiaryPage,
    ListBeneficiaryPage,
    ListUserPage,
    FormUserPage,
    ProfilePage,
    ChangePassWordPage,
    MetricFormPage,
    MetricListPage,
    SegmentFormPage,
    SegmentListPage,
    DashCostEffectiveness,
    JourneyMenuPage,
    PredictiveAnalyticsPage,
    PatientDetailPage
} from '../../pages/';
import routes from '../routes';

function AdminRoutes() {
    const { adminRoutes } = routes;

    return (
        <Switch>
            { /* Admin  */ }
            
            {/* <Route exact path={adminRoutes.home} component={HomePage} /> */}

            <Route exact path={adminRoutes.profile} component={ProfilePage} />
            <Route exact path={adminRoutes.changePassword} component={ChangePassWordPage} />

            <Route exact path={adminRoutes.beneficiaryList} component={ListBeneficiaryPage} />
            <Route exact path={adminRoutes.beneficiaryForm} component={FormBeneficiaryPage} />
            
            <Route exact path={adminRoutes.healthInsuranceList} component={HealthInsuranceListPage} />
            <Route exact path={adminRoutes.healthInsuranceForm} component={HealthInsuranceFormPage} />

            <Route exact path={adminRoutes.serviceProviderList} component={ListServiceProviderPage} />
            <Route exact path={adminRoutes.serviceProviderForm} component={FormServiceProviderPage} />
            <Route exact path={adminRoutes.serviceProviderImport} component={ImportServiceProviderPage} />

            <Route exact path={adminRoutes.metricList} component={MetricListPage} />
            <Route exact path={adminRoutes.metricForm} component={MetricFormPage} />

            <Route exact path={adminRoutes.segmentList} component={SegmentListPage} />
            <Route exact path={adminRoutes.segmentForm} component={SegmentFormPage} />

            <Route exact path={adminRoutes.userList} component={ListUserPage} />
            <Route exact path={adminRoutes.userForm} component={FormUserPage} />

            <Route exact path={adminRoutes.dashCostEffectiveness} component={DashCostEffectiveness} />
            <Route exact path={adminRoutes.journeyMenu} component={JourneyMenuPage} />
            <Route exact path={adminRoutes.predictiveAnalytics} component={PredictiveAnalyticsPage} />
            <Route exact path={adminRoutes.patientDetailPage} component={PatientDetailPage} />


            {/*<FormRoutes />*/}
            <Route component={NotFoundPage} />
        </Switch>
    );
}

export default AdminRoutes;
