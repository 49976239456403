import React from 'react';
import Modal from '@material-ui/core/Modal';
import Loading from 'shared/components/Loading';


export default function LoadingModal(props) {
  const { isLoading } = props;

  return (
    <Modal
      open={isLoading}
    >
      <div>
        <Loading />
      </div>
    </Modal>
  );
}
