import React from 'react';
import { PropTypes } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import cssLoading from 'styles/components/Loading.scss';

function LoadingBox(props) {
  return (
    <div className={cssLoading.OutboxCircular2}>
      <div className={cssLoading.boxCircular2}>
        <div>
          <CircularProgress className={cssLoading.circularP} size={60} thickness={1} color="secondary" />
        </div>
        <div>
          Carregando...
        </div>
      </div>
    </div>
  );
}

LoadingBox.propTypes = {
};
export default (LoadingBox);
