import Swal from 'sweetalert2';

export function error(message) {
  Swal.fire({
    title: 'Error',
    text: message,
    icon: 'error',
    confirmButtonText: 'Ok',
  });
}

export function successComplete(title2,message) {
  Swal.fire({
    title: title2,
    html: message,
    icon: 'success',
    confirmButtonText: 'Ok',
  });
}

export function successCompleteCopy(title2,message) {
  Swal.fire({
    title: title2,
    html: message,
    icon: 'success',
    showCloseButton: true,
    showCancelButton: true,
    cancelButtonText:'Fechar',
    confirmButtonText: 'Copiar',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      navigator.clipboard.writeText(message)

      Swal.fire('Copiado!', '', 'success')
    }
  })
}

export function success(message) {
  Swal.fire({
    title: 'Csv importado',
    html: message,
    icon: 'success',
    confirmButtonText: 'Ok',
  });
}

export function toastSuccess(message) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  Toast.fire({
    icon: 'success',
    title: message
  });
}

