import { Api } from 'config';

const serviceProviderEndpoint = 'ServiceProvider';

export async function getAllByPageAndName(PageNumber, PageSize, Name) {
  const params = {
    PageNumber,
    PageSize,
    Name,
  };

  const response = await Api.get(serviceProviderEndpoint, params);
  return response;
}

export async function getById(id) {
  const endpoint = serviceProviderEndpoint + `/${id}`;
  const response = await Api.get(endpoint);

  return response;
}

export async function create(payload) {
  const response = await Api.post(serviceProviderEndpoint, payload);

  return response;
}

export async function update(id,payload) {
  const endpoint = serviceProviderEndpoint + `/${id}`;
  const response = await Api.put(endpoint, payload);

  return response;
}

export async function getPlanilhaCsv() {
  const endpoint = serviceProviderEndpoint + `/Planilha`;
  const response = await Api.get(endpoint);

  return response;
}

export async function importPlanilhaCsv(payload) {
  const endpoint = serviceProviderEndpoint + `/Csv`;
  const response = await Api.post(endpoint, payload);

  return response;
}

export async function getActive() {
  const endpoint =serviceProviderEndpoint + `/Active`;
  const response = await Api.get(endpoint);

  return response;
}