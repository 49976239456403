import { Api } from 'config';

const segmentEndpoint = 'Segment';

export async function getAllByPageAndName(PageNumber, PageSize, Name) {
  const params = {
    PageNumber,
    PageSize,
    Name,
  };

  const response = await Api.get(segmentEndpoint, params);
  return response;
}

export async function getById(id) {
  const endpoint = segmentEndpoint + `/${id}`;
  const response = await Api.get(endpoint);

  return response;
}

export async function create(payload) {
  const response = await Api.post(segmentEndpoint, payload);

  return response;
}

export async function update(id,payload) {
  const endpoint = segmentEndpoint + `/${id}`;
  const response = await Api.put(endpoint, payload);

  return response;
}

export async function getActive() {
  const endpoint =segmentEndpoint + `/Active`;
  const response = await Api.get(endpoint);

  return response;
}