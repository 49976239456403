const { default: routes } = require('navigation/routes');
const { adminRoutes } = routes;

module.exports = [
  // {
  //   key: 'home',
  //   name: 'Home',
  //   icon: 'ios-home-outline',
  //   linkParent: adminRoutes.home
  // },
  // {
  //   key: 'cadastros',
  //   name: 'Cadastros',
  //   icon: 'ios-list-box-outline',
  //   child: [
  //     {
  //       key: 'data_table',
  //       name: 'Convênios',
  //       link: adminRoutes.healthInsuranceList,
  //       icon: 'ios-cube-outline'
  //     },
  //     {
  //       key: 'data_table',
  //       name: 'Prestador de Serviços',
  //       link: adminRoutes.serviceProviderList,
  //       icon: 'ios-cube-outline'
  //     },
  //     {
  //       key: 'data_table',
  //       name: 'Beneficiários',
  //       link: adminRoutes.beneficiaryList,
  //       icon: 'ios-cube-outline'
  //     },
  //     {
  //       key: 'data_table',
  //       name: 'Segmentos',
  //       link: adminRoutes.segmentList,
  //       icon: 'ios-cube-outline'
  //     }
  //     /*{
  //       key: 'data_table',
  //       name: 'Métricas',
  //       link: adminRoutes.metricList,
  //       icon: 'ios-cube-outline'
  //     },
  //     {
  //       key: 'data_table',
  //       name: 'Segmentos',
  //       link: adminRoutes.segmentList,
  //       icon: 'ios-cube-outline'
  //     }*/
  //   ]
  // },
  {
    key: 'journeyMenu',
    name: 'Jornadas',
    icon: 'ios-paper-plane-outline',
    linkParent: adminRoutes.journeyMenu,
  },
  {
    key: 'dashCostEffectiveness',
    name: 'Custo Efetividade',
    icon: 'ios-sync',
    linkParent: adminRoutes.dashCostEffectiveness,
  },
  {
    key: 'patientDetail',
    name: 'Pacientes',
    icon: 'ios-person-outline',
    linkParent: adminRoutes.patientDetail,
  },
  /*{
    key: 'setup',
    name: 'Setup Pontuação',
    icon: 'ios-settings-outline',
    linkParent: adminRoutes.punctuationSetupList,
  }*/
];
